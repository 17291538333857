
.fp-hidden-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.fp-hidden-input  {
    width: 100%;
    height: 100%;
    display: none;
}

.fp-main-area {

    display: flex;
    width: 60%;
    height: 20rem;
    border-radius: 20px;
    border-style: dashed;
    border-width: 2px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    user-select: none;
    font-weight: 250;

}

.fp-main-area:hover {

    background-color: rgba(128, 128, 128, 0.204);
    cursor: pointer;

}

svg {

    fill: white;
    width: 5rem;

}

.fp-upload-text {
    text-align: center;
}

.fp-upload-subtitle {
    font-size: smaller;
    font-style: italic;
    color: gray;
}

p {

    padding: 0;
    margin: 0;

}

.fp-progress-bar {
    width: 100%;
}