html {

  background-color: #282c34;
  color: whitesmoke;

}

footer a {

  font-weight: 500;
  text-decoration: none;

}

footer a:link,
footer a:active,
footer a:visited {
  color: whitesmoke;
}

footer a:hover {
  color: gray;
}