.upload-header {

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 2rem;
    padding-left: 6rem;
    padding-right: 6rem;

}

.upload-header ul {

    display: flex;
    gap: 2rem;
    list-style: none;
    padding: 0;
    margin: 0;

}

.upload-header li:hover {

    color: gray;
    cursor: pointer;
    border-color: gray;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;

}

.upload-header li {

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    width: 8rem;
    height: 3rem;

    border-color: transparent;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;

}

#logo {

    display: flex;
    font-weight: 900;
    font-size: 30px;
    vertical-align: middle;
    font-family: 'Courier New', Courier, monospace;

}

@media screen and (max-width: 580px) {

    .upload-header ul {
        display: none;
    }

    .upload-header {

        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;

    }

}