.upload-body {

    padding-top: 5rem;
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5rem;

}

.upload-picker-area {
    width: 100%;
    text-align: center;
}

.upload-footer {

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    height: 3rem;

}

.upload-disk-area {

    font-size: 15px;
    text-align: center;
    width: 40%;

}