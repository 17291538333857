
.download-body {

    padding-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

}

.download-file-card {

    display: flex;
    gap: 1rem;
    line-height: 30px;

}

.download-buttons {

    display: flex;
    justify-content: space-around;
    gap: 5rem;

}